import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ability from "@/libs/acl/ability";

export default function useFaqsList() {
  // Use toast
  const toast = useToast();

  const refFaqListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    // { key: "id", label: "ID", sortable: true },
    { key: "actions" },
    { key: "question", label: "Question" },
    { key: "answer", label: "Answer" },
    { key: "is_active", label: "Active" },
    { key: "sort_order", label: "Sort Order" },
  ];
  const perPage = ref(10);
  const totalFaqs = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const statusFilter = ref(null);
  const is_active = ref(1);

  const dataMeta = computed(() => {
    const localItemsCount = refFaqListTable.value
      ? refFaqListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalFaqs.value,
    };
  });

  const refetchData = () => {
    console.log("refFaqListTable", refFaqListTable);
    refFaqListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, statusFilter, is_active], () => {
    refetchData();
  });

  const fetchFaqs = (ctx, callback) => {
    store
      .dispatch("app-faqs/fetchFaqs", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        console.log(response);
        const { faqs, total } = response.data;
        callback(faqs);
        totalFaqs.value = total;
      })
      .catch((err) => {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching faqs list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };


  return {
    fetchFaqs,
    tableColumns,
    perPage,
    currentPage,
    totalFaqs,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refFaqListTable,
    ability,
    refetchData,

    // Extra Filters
    statusFilter,
    is_active,
  };
}
